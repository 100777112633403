import React, { FC } from 'react'
import { Tabs, Tab, Panel } from '@bumaga/tabs'

import DevicesTableComponent from './DevicesDataTable'
import { DeviceStats } from './DeviceStats'
import './core/styles.css'

export const DevicesPage: FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <div className="g-5 g-xl-8">
      <Tabs>
        <div className="nav mb-3">
          <Tab>
            <div className="navItem">Device List</div>
          </Tab>
          <Tab>
            <div className="navItem">Stats</div>
          </Tab>
        </div>
        <div className="">
          <Panel>
            <DevicesTableComponent />
          </Panel>
          <Panel>
            <DeviceStats />
          </Panel>
        </div>
      </Tabs>
    </div>
  </>
)
