import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

type Props = {
  className: string
  title: string
}

const FraudWarning: React.FC<React.PropsWithChildren<Props>> = ({ className, title }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <a href='#' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            New Device
          </a>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted bg-light">
                <th className="ps-4 min-w-320px rounded-start">Station</th>
                <th className="min-w-130px pe-4 text-end">Fraud Rate</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td className="ps-4">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Kawempe Division
                  </a>
                </td>
                <td className="pe-4 text-end">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    40.00%
                  </a>
                </td>
              </tr>
              <tr>
                <td className="ps-4">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Rubaga division
                  </a>
                </td>
                <td className="pe-4 text-end">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    27.78%
                  </a>
                </td>
              </tr>
              <tr>
                <td className="ps-4">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Makindye Division
                  </a>
                </td>
                <td className="pe-4 text-end">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    0.00%
                  </a>
                </td>
              </tr>
              <tr>
                <td className="ps-4">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Kiswa Health Centre III
                  </a>
                </td>
                <td className="pe-4 text-end">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    11.11%
                  </a>
                </td>
              </tr>
              <tr>
                <td className="ps-4">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Kitebi Health Centre III
                  </a>
                </td>
                <td className="pe-4 text-end">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    7.27%
                  </a>
                </td>
              </tr>
              <tr>
                <td className="ps-4">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    KCCA Headquarters
                  </a>
                </td>
                <td className="pe-4 text-end">
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    21.35%
                  </a>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { FraudWarning }
