export enum Role {
  Station = 'station',
  ClientBody = 'clientBody',
  Client = 'client',
  Internal = 'internal'
}

export enum Type {
  External = 'external',
  Internal = 'internal'
}
export interface IUser {
  _id?: string
  email?: string
  username?: string
  name?: string
  password?: string
  phoneNumber?: string
  role: string
  type: string
  profilePhoto?: string
  parentUserIds?: string[]
}

export interface UserByRole {
  _id: string
  name: string
  user_Id: string
}

export interface AuthBody {
  email: string
  password: string
}

export interface FiltersRequestBody {
  type: string
  name?: string
}

export interface AuthAction {
  type: string
  body?: AuthBody
}

export interface Filters {
  role?: string
  stationList?: string[]
  clientBodyList?: string[]
}

export interface InterviewFormData {
  name: string
  type: string
  startDate: Date | null
  endDate: Date | null
  interview: string | Blob
}

export interface ReportData {
  _id?: string
  name: string
  download_link?: string
  period?: Array<string>
  startDate?: Date
  endDate?: Date
  month?: string
  year?: string
  type: string
  recipientIds: Array<string> | string
  createdAt?: string
  updatedAt?: string
  report?: File | null | undefined
  __v?: number
}

export interface ReportFormData {
  name: string
  startDate: Date | null
  endDate: Date | null
  type: string
  selectedClient: any
  selectedClientBody: any
  selectedStation: any
  report: string | Blob
}

export interface DeviceData {
  _id: string
  test_device: false
  battery: number
  device_id: string
  device_imei: string
  device_name: string
  createdAt: string
  updatedAt: string
  __v: number
  token: string
  status: number
  category: string
  assignment: {
    station?: string
    clientBody?: string
    client?: string
    hasClientBody: boolean
    placement: string
    deploymentDate: string
  }
  active: boolean
  faulty: boolean
  numberOfFaults: number
  faultDetectedAt: string
  id: string
  showDownload: boolean
  client?: string
  clientBody?: string
}

export interface CreateReport {
  name: string
  type: string
  recipientIds?: string
  startDate: Date
  endDate: Date
  report: File | null | undefined
}

export interface ICreateDevice {
  device_imei: string
  device_name: string
  placement: string
  category: string
  active: boolean
  userId: string
  deploymentDate: Date | null
}

export enum QueryKey {
  Users = 'users',
  User = 'user',
  Interviews = 'interviews',
  Interview = 'interview',
  Reports = 'reports',
  Report = 'report',
  Devices = 'devices',
  Device = 'device',
  UsersByRole = 'usersByRole',
  Station = 'station',
  ClientBody = 'clientBody',
  Client = 'client',
  Internal = 'internal'
}
