import { useState, useCallback } from 'react'
import { KTSVG } from '../../helpers'
import MUIDataTable from 'mui-datatables'
import CustomToolbar from './components/toolbar/custom-tb'
import ConfirmDelete from './components/confirmdelete/confirmDelete'
import { LoadingSpinner } from '../shared_components/LoadingSpinner'
import { CreateReports } from './components/forms/create-reports'
import { useQuery } from '@tanstack/react-query'
import { getReportData } from '../../apis/app.service'
import { PdfViewer } from './components/pdfviewer/PdfViewer'
import moment from 'moment'
import './core/style.css'

const ReportsTableComponent = () => {
  const [selectedRecordId, setSelectedUserId] = useState('')
  const [selectedRecordName, setSelectedRecordName] = useState('')
  const [selectedRecordData, setSelectedRecordData] = useState({ action: 'Create' })
  const [showPdf, setShowPdf] = useState(false)
  const [pdf, setPdf] = useState('')

  const handleFormDisplay = useCallback((inits) => {
    setSelectedRecordData(inits)
  }, [])

  const columns = [
    {
      name: 'name',
      label: 'Report Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'updatedAt',
      label: 'Upload Date',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div className="">{moment(value).format('DD/MM/YYYY')}</div>
        }
      }
    },
    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {/* begin::Menu */}
              <div className="d-flex flex-row align-content-center w-125px py-2" data-kt-menu="true">
                {/* begin::Menu item */}
                <a
                  className="btn-icon-default pe-5"
                  onClick={() => {
                    setPdf(tableMeta.tableData[tableMeta.rowIndex].download_link)
                    setShowPdf(true)
                  }}
                >
                  <i className="fas fa-eye btn-icon-default" data-bs-toggle="tooltip" title="View"></i>
                </a>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <a
                  className="btn-icon-default pe-5"
                  onClick={() => {
                    const data = { data: tableMeta.tableData[tableMeta.rowIndex], action: 'Edit' }
                    setSelectedRecordData(data)
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_report"
                >
                  <i className="fas fa-edit btn-icon-default" data-bs-toggle="tooltip" title="Edit"></i>
                </a>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <a
                  className="btn-icon-default pe-5"
                  onClick={() => {
                    setSelectedUserId(tableMeta.tableData[tableMeta.rowIndex]._id)
                    setSelectedRecordName(tableMeta.tableData[tableMeta.rowIndex].name)
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_delete"
                >
                  <i className="fas fa-trash btn-icon-default" data-bs-toggle="tooltip" title="Delete"></i>
                </a>
                {/* end::Menu item */}
                <a
                  className="btn-icon-default"
                  onClick={() =>
                    downloadFile(
                      tableMeta.tableData[tableMeta.rowIndex].download_link,
                      tableMeta.tableData[tableMeta.rowIndex].name
                    )
                  }
                >
                  <i className="fas fa-download btn-icon-default" data-bs-toggle="tooltip" title="Download"></i>
                </a>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          )
        }
      }
    }
  ]

  const options = {
    filterType: 'dropdown',
    viewColumns: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'sema_reports.csv', separator: ',' },
    elevation: 1,
    customToolbar: () => {
      return <CustomToolbar handleFormDisplay={handleFormDisplay} />
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div className="card-title">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG path="/media/icons/duotune/general/gen021.svg" className="svg-icon-1 position-absolute ms-6" />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14 mt-3"
              placeholder="Search Report"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
      )
    }
  }

  const { data, isLoading, isError, error } = useQuery(['reports'], getReportData)

  const downloadFile = (downloadLink, name) => {
    fetch(downloadLink, {
      method: 'GET'
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = `${name}.pdf`
        a.click()
      })
    })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <div className="w-75 mr-auto">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
        <p>{error.message}</p>
      </div>
    )
  }

  return (
    <>
      <MUIDataTable title={'Reports'} data={data} columns={columns} options={options} />

      <CreateReports inits={selectedRecordData} />
      <PdfViewer pdf={pdf} onCancel={() => setShowPdf(false)} visible={showPdf} />
      <ConfirmDelete recordId={selectedRecordId} recordName={selectedRecordName} />
    </>
  )
}

export default ReportsTableComponent
