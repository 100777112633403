import { PropagateLoader } from 'react-spinners'

import { toAbsoluteUrl } from '../../helpers/AssetHelpers'

export const LoadingSpinner = () => {
  return (
    <div className="w-100 h-300px mr-auto ml-auto d-flex flex-column align-items-center justify-content-center">
      <div className="h-75"> </div>
      <img
        src={toAbsoluteUrl('media/logos/sema-logo.png')}
        alt="SEMA logo"
        className="align-self-center mb-2 h-50px w-50px"
      />
      <PropagateLoader className="align-self-center" color="#07031FF9" size={10} />
    </div>
  )
}
