import React, { FC } from 'react'
import InterviewsDataTable from './InterviewsDataTable'

const InterviewsPage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <InterviewsDataTable />
    </>
  )
}

export { InterviewsPage }
