import React, { FC, useState, useEffect } from 'react'
import cogoToast from 'cogo-toast'
import { KTSVG } from '../../../../helpers'
import { Formik, Form, FormikValues, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { IUser, Role } from '../../../../apis/models'
import '../../core/style.css'
import { createNewUser, getUsersByRole, updateUser } from '../../../../apis/app.service'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../../../apis/models'
import Multiselect from 'multiselect-react-dropdown'
import Select from 'react-select'

import '../../core/style.css'

interface IFormValues {
  email: string
  firstname: string
  lastname: string
  username: string
  cpassword?: string
  password?: string
  phoneNumber: string
  type: string
  role: string
}

type Option = {
  name: string
  _id: string
  user_Id: string
}

type Props = {
  inits?: any
  data?: unknown
}

const roleOptions = [
  { value: 'client', label: 'client' },
  { value: 'clientBody', label: 'clientBody' },
  { value: 'station', label: 'station' },
  { value: 'admin', label: 'admin' },
  { value: 'data', label: 'data' }
]

const typeOptions = [
  { value: 'external', label: 'external' },
  { value: 'internal', label: 'internal' }
]

const userSchema = Yup.object({
  firstname: Yup.string().required().label('First Name'),
  lastname: Yup.string().required().label('Last Name'),
  email: Yup.string().required().label('Email'),
  username: Yup.string().required().label('Username'),
  role: Yup.string().required().label('Role'),
  type: Yup.string().required().label('Type'),
  phoneNumber: Yup.number().required().label('Phone Number')
})

const CreateUser: FC<React.PropsWithChildren<Props>> = (props) => {
  const { action, data } = props.inits
  const [selectedClient, setSelectedClient] = useState<any>([])
  const [selectedClientBody, setSelectedClientBody] = useState<any>([])
  const [tags, setTags] = useState<string[]>([])
  const [formIntialValues, setFormIntialValues] = useState<IFormValues>({
    email: '',
    username: '',
    firstname: '',
    lastname: '',
    password: '',
    cpassword: '',
    phoneNumber: '',
    type: '',
    role: ''
  })

  const queryClient = useQueryClient()

  const { data: clientIds } = useQuery([QueryKey.ClientBody], () => getUsersByRole(Role.Client))

  const { data: clientBodyIds } = useQuery([QueryKey.Station], () => getUsersByRole(Role.ClientBody))

  useEffect(() => {
    let inits: IFormValues = {
      email: '',
      username: '',
      firstname: '',
      lastname: '',
      password: '',
      cpassword: '',
      phoneNumber: '',
      type: '',
      role: ''
    }
    setSelectedClient([])
    setSelectedClientBody([])
    setTags([])

    if (action === 'Edit') {
      const client: any = clientIds.filter((user: Option) => data.parentUserIds.includes(user._id))
      const clientBody: any = clientBodyIds.filter((user: Option) => data.parentUserIds.includes(user._id))

      setSelectedClient(client)
      setSelectedClientBody(clientBody)
      setTags(data.parentUserIds)

      inits = {
        email: data.email,
        username: data.username,
        firstname: data.name.split(' ')[0],
        lastname: data.name.split(' ')[1],
        phoneNumber: data.phoneNumber,
        password: '',
        cpassword: '',
        type: data.type,
        role: data.role
      }
    }
    setFormIntialValues(inits)
  }, [action, data])

  const mutation = useMutation(createNewUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.Users])
      const { message } = data
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const mutationUpdate = useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.Devices])
      const { message } = data
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const onSelect = (selectedList: Option[], selectedItem: Option) => {
    tags.push(selectedItem._id)
  }

  const onRemove = (selectedList: Option[], selectedItem: Option) => {
    const index = tags.indexOf(selectedItem._id)
    tags.splice(index, 1)
  }

  const submitForm = async (values: IFormValues, actions: FormikValues) => {
    if (values.password !== values.cpassword) {
      cogoToast.error('Passwords not matching', { hideAfter: 3, position: 'top-right' })
      return
    }

    const user: IUser = {
      email: values.email,
      username: values.username,
      name: `${values.firstname} ${values.lastname}`,
      password: values.password,
      phoneNumber: values.phoneNumber,
      role: values.role,
      type: values.type,
      profilePhoto: '',
      parentUserIds: tags
    }

    if (action === 'Create') {
      mutation.mutate(user)
    } else if (action === 'Edit') {
      mutationUpdate.mutate({ userId: data._id, body: user })
    }
  }

  return (
    <div className="modal fade" id="kt_modal_create_user" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{action} User</h2>

            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            {/* begin body */}
            <div className="flex-row-fluid py-lg-5 px-lg-15">
              <Formik
                validationSchema={userSchema}
                initialValues={formIntialValues}
                onSubmit={submitForm}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form className="form" noValidate>
                    <div className="w-100 d-flex flex-column justify--center">
                      <div className="w-100">
                        <div className="row mb-5">
                          <div className="col form-group">
                            <label htmlFor="firstname" className="col fs-5 fw-bold mb-2">
                              First Name
                            </label>

                            <Field type="text" className="form-control" name="firstname" placeholder="" />
                            <div className="text-danger">
                              <ErrorMessage name="firstname" />
                            </div>
                          </div>

                          <div className="col form-group">
                            <label htmlFor="lastname" className="fs-5 fw-bold mb-2">
                              Last Name
                            </label>

                            <Field type="text" className="form-control" name="lastname" placeholder="" />
                            <div className="text-danger">
                              <ErrorMessage name="lastname" />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col form-group">
                            <label htmlFor="username" className="fs-5 fw-bold mb-2">
                              User Name
                            </label>

                            <Field type="text" className="form-control" name="username" />
                            <div className="text-danger">
                              <ErrorMessage name="username" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-5">
                          <div className="col form-group">
                            <label className="fs-5 fw-bold mb-2">Contact Email</label>

                            <Field type="text" className="form-control" name="email" placeholder="" />
                            <div className="text-danger">
                              <ErrorMessage name="email" />
                            </div>
                          </div>
                          <div className="col form-group">
                            <label htmlFor="phoneNumber" className="fs-5 fw-bold mb-2">
                              Phone
                            </label>

                            <Field type="text" className="form-control" name="phoneNumber" placeholder="" />
                            <div className="text-danger">
                              <ErrorMessage name="phoneNumber" />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-5">
                          <div className="col form-group">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Role</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="role"
                              ></i>
                            </label>

                            <Select
                              name="type"
                              onChange={(selectedOption: any) => formik.setFieldValue('role', selectedOption.value)}
                              options={roleOptions}
                              value={{ value: formik.values.role, label: formik.values.role }}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="type" />
                            </div>
                          </div>

                          <div className="col form-group">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Type</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="type"
                              ></i>
                            </label>

                            <Select
                              name="role"
                              onChange={(selectedOption: any) => formik.setFieldValue('type', selectedOption.value)}
                              options={typeOptions}
                              value={{ value: formik.values.type, label: formik.values.type }}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="role" />
                            </div>
                          </div>
                        </div>

                        {action === 'Create' && (
                          <div className="row mb-5">
                            <div className="col form-group">
                              <label className="fs-5 fw-bold mb-2">Create Password</label>

                              <Field type="password" className="form-control" name="password" placeholder="" />
                              <div className="text-danger">
                                <ErrorMessage name="password" />
                              </div>
                            </div>

                            <div className="col form-group">
                              <label className="fs-5 fw-bold mb-2">Confirm Password</label>

                              <Field type="password" className="form-control" name="cpassword" placeholder="" />
                              <div className="text-danger">
                                <ErrorMessage name="cpassword" />
                              </div>
                            </div>
                          </div>
                        )}

                        {(formik.values.role === 'station' || formik.values.role === 'clientBody') && (
                          <div className="row mb-5">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span>Parent User</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="role"
                              ></i>
                            </label>
                            <div className="my-2">
                              <Multiselect
                                options={clientIds} // Options to display in the dropdown
                                className="col"
                                placeholder="Client"
                                selectedValues={selectedClient}
                                style={{
                                  chips: {
                                    // To change css chips(Selected options)
                                    background: '#F3992B'
                                  },
                                  optionContainer: {
                                    // To change css for option container
                                    border: 'none'
                                  },
                                  option: {
                                    // To change css for dropdown options
                                    // color: blue;
                                  }
                                }}
                                onSelect={(list: Option[], item: Option) => onSelect(list, item)} // Function will trigger on select event
                                onRemove={(list: Option[], item: Option) => onRemove(list, item)} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                              />
                            </div>
                            {formik.values.role === 'station' && (
                              <div className="my-2">
                                <Multiselect
                                  options={clientBodyIds} // Options to display in the dropdown
                                  className="col"
                                  placeholder="Institution"
                                  selectedValues={selectedClientBody}
                                  style={{
                                    chips: {
                                      // To change css chips(Selected options)
                                      background: '#F3992B'
                                    },
                                    optionContainer: {
                                      // To change css for option container
                                      border: 'none'
                                    },
                                    option: {
                                      // To change css for dropdown options
                                      // color: blue;
                                    }
                                  }}
                                  onSelect={(list: Option[], item: Option) => onSelect(list, item)} // Function will trigger on select event
                                  onRemove={(list: Option[], item: Option) => onRemove(list, item)} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="mx-auto">
                        <button type="submit" className="btn btn-default d-flex">
                          {(!mutation.isLoading || !mutationUpdate.isLoading) && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {(mutation.isLoading || mutationUpdate.isLoading) && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CreateUser }
