import React from 'react'

type Props = {
  className: string
  title: string
}

const TablesWidget11: React.FC<React.PropsWithChildren<Props>> = ({ className, title }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <a href='#' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            New Device
          </a>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            {/* <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-325px rounded-start'>Device</th>
                <th className='min-w-125px'>Period/Status</th>
              </tr>
            </thead> */}
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Queen's Way
                  </a>
                </td>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    5 days ago
                  </a>
                  <span className="badge badge-light-success fs-7 fw-bold">Active</span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    KCCA Wandegeya
                  </a>
                </td>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    10 days ago
                  </a>
                  <span className="badge badge-light-success fs-7 fw-bold">Active</span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Police Headquaters
                  </a>
                </td>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    25 days ago
                  </a>
                  <span className="badge badge-light-warning fs-7 fw-bold">In Maintenance</span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Kololo Airstrip
                  </a>
                </td>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    2 months ago
                  </a>
                  <span className="badge badge-light-danger fs-7 fw-bold">Faulty</span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    Bukoto Traffic Lights
                  </a>
                </td>
                <td>
                  <a href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">
                    5 months ago
                  </a>
                  <span className="badge badge-light-success fs-7 fw-bold">Active</span>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget11 }
