import React, { FC } from 'react'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KTSVG } from '../../../../helpers'
import { updateDevice } from '../../../../apis/app.service'
import { QueryKey } from '../../../../apis/models'

type Props = {
  deviceId?: string
  deviceName?: string
  isActive?: boolean
}

const ConfirmActive: FC<React.PropsWithChildren<Props>> = (props) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(updateDevice, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries([QueryKey.Devices])
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const handleSave = (params: { deviceId: string; body: any }) => {
    cogoToast.info('Device updating', { hideAfter: 2, position: 'top-right' })
    mutation.mutate(params)
  }

  return (
    <>
      <div className="modal fade" id="kt_modal_confirm_active" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-900px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Confirm Action</h2>

              <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
              </div>
            </div>

            <div className="modal-body py-lg-5 px-lg-5">
              Are you sure you want to turn this device on <strong>{props.deviceName}?</strong>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => handleSave({ deviceId: props.deviceId ?? '', body: { active: props.isActive } })}
                data-bs-dismiss="modal"
                className="btn btn-lg btn-primary me-3"
              >
                {!mutation.isLoading && <span className="indicator-label">save</span>}
                {mutation.isLoading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default ConfirmActive
