import React, { useState, useCallback } from 'react'
import { KTSVG } from '../../helpers'
import MUIDataTable from 'mui-datatables'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import CustomToolbar from './components/toolbar/custom-tb'
import { CreateUser } from './components/forms/create-user'
import ConfirmUserActive from './components/popups/confirm-active'
import ConfirmDelete from '../shared_components/confirmDelete'
import UpdatePassword from './components/popups/update-password'
import ConfirmPasswordReset from './components/popups/confirm-password-reset'
import { getUsers } from '../../apis/app.service'
import { useQuery } from '@tanstack/react-query'
import { LoadingSpinner } from '../shared_components/LoadingSpinner'
import { QueryKey } from '../../apis/models'
import './core/style.css'
const muiCache = createCache({
  key: 'mui',
  prepend: true
})

const UsersTableComponent = () => {
  const [selectedUserId, setSelectedUserId] = useState('')
  const [selectedUserName, setSelectedUserName] = useState('')
  const [selectedIsActive, setSelectedIsActive] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [selectedUserData, setSelectedUserData] = useState({ action: 'Create', data: {} })

  const handleFormDisplay = useCallback((inits) => {
    setSelectedUserData(inits)
  }, [])
  const handlePasswordReset = useCallback((password) => {
    setNewPassword(password)
  }, [])

  const columns = [
    {
      name: 'user_Id',
      label: 'ID',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'name',
      label: 'Credentials',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const data = tableMeta.tableData[tableMeta.rowIndex]
          return (
            <div>
              <div className="row">
                <p className="col-2">
                  <b>Name:</b>
                </p>
                <p className="col-10">{data.name}</p>
              </div>
              <div className="row">
                <p className="col-2">
                  <b>Email:</b>
                </p>
                <p className="col-10">{data.email}</p>
              </div>
              <div className="row">
                <p className="col-2">
                  <b>Phone:</b>
                </p>
                <p className="col-10">{data.phoneNumber}</p>
              </div>
            </div>
          )
        }
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const data = tableMeta.tableData[tableMeta.rowIndex]
          return (
            <div>
              <div
                className="form-check form-check-solid form-switch"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_user_confirm_active"
              >
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  checked={value}
                  onChange={(e) => {
                    setSelectedUserId(data._id)
                    setSelectedUserName(data.name)
                    setSelectedIsActive(e.target.checked)
                  }}
                />
              </div>
            </div>
          )
        }
      }
    },
    {
      name: 'total',
      label: 'No. of presses',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'averageSatisfaction',
      label: 'Average %',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'numberOfLogins',
      label: 'No. of Logins',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <div className="">{value ?? 0} </div>
        }
      }
    },
    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {/* begin::Menu */}
              <div className="d-flex flex-row align-content-center w-125px py-4" data-kt-menu="true">
                {/* begin::Menu item */}
                <a
                  className="btn-icon-default pe-5"
                  onClick={() => {
                    const data = tableMeta.tableData[tableMeta.rowIndex]
                    setSelectedUserData({ data, action: 'Edit' })
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_user"
                >
                  <i className="fas fa-edit btn-icon-default" title="Edit"></i>
                </a>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <a
                  className="btn-icon-default pe-5"
                  onClick={() => {
                    setSelectedUserId(tableMeta.tableData[tableMeta.rowIndex]._id)
                    setSelectedUserName(tableMeta.tableData[tableMeta.rowIndex].name)
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_delete"
                >
                  <i className="fas fa-trash btn-icon-default" title="Delete"></i>
                </a>
                <a
                  className="btn-icon-default pe-5"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_reset"
                  onClick={() => {
                    setSelectedUserId(tableMeta.tableData[tableMeta.rowIndex]._id)
                    setSelectedUserName(tableMeta.tableData[tableMeta.rowIndex].name)
                  }}
                >
                  <i className="fas fa-key btn-icon-default" title="password reset"></i>
                </a>

                <a
                  className="btn-icon-default"
                  onClick={() =>
                    downloadFile(
                      tableMeta.tableData[tableMeta.rowIndex].user_Id,
                      tableMeta.tableData[tableMeta.rowIndex].name
                    )
                  }
                >
                  <i className="fas fa-download btn-icon-default" data-bs-toggle="tooltip" title="Device data"></i>
                </a>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          )
        }
      }
    }
  ]

  const options = {
    filterType: 'dropdown',
    viewColumns: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'TalkToSemaUsers.csv', separator: ',' },
    elevation: 1,
    customToolbar: () => {
      return <CustomToolbar handleFormDisplay={handleFormDisplay} />
    },
    customSearchRender: (searchText, handleSearch) => {
      return (
        <div className="card-title">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG path="/media/icons/duotune/general/gen021.svg" className="svg-icon-1 position-absolute ms-6" />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14 mt-3"
              placeholder="Search user"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
      )
    }
  }

  const downloadFile = (userId, name) => {
    //TODO: Consider adding auth to these endpoint because its public now
    const downloadLink = `${process.env.REACT_APP_BASE_URL}/api/user/${userId}/feedback`
    fetch(downloadLink, {
      method: 'GET'
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = `${name}.csv`
        a.click()
      })
    })
  }

  const { data, isLoading, isError } = useQuery([QueryKey.Users], getUsers, { keepPreviousData: true })

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <div className="d-flex justify-center align-content-center">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
      </div>
    )
  }

  return (
    <>
      <CacheProvider value={muiCache}>
        <MUIDataTable title="Users List" data={data} columns={columns} options={options} />
        <CreateUser inits={selectedUserData} />
        <UpdatePassword currentPassword={newPassword} />
        <ConfirmPasswordReset
          handlePasswordReset={handlePasswordReset}
          recordId={selectedUserId}
          recordName={selectedUserName}
        />
        <ConfirmDelete recordId={selectedUserId} recordName={selectedUserName} />
        <ConfirmUserActive userId={selectedUserId} userName={selectedUserName} isActive={selectedIsActive} />
      </CacheProvider>
    </>
  )
}

export default UsersTableComponent
