import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { KTSVG } from '../../../../helpers'
import { withStyles } from '@material-ui/core/styles'
import '../../core/style.css'

const defaultToolbarStyles = {
  iconButton: {}
}

const CustomToolbar = (props) => {
  const openForm = () => {
    props.handleFormDisplay({ data: {}, action: 'Create' })
  }

  return (
    <React.Fragment>
      <Tooltip title={'Add Report'}>
        {/* begin::Add user */}
        <button
          type="button"
          className="btn btn-default"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_report"
          onClick={() => openForm()}
        >
          <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
          Add Report
        </button>
        {/* end::Add user */}
      </Tooltip>
    </React.Fragment>
  )
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(CustomToolbar)
