import React, { FC } from 'react'
import {
  ButtonPresses,
  DeviceUsage,
  FraudWarning,
  PercentageDistButtonPresses,
  SatisfactionTrends
} from '../../partials/widgets'

export const DeviceStats: FC<React.PropsWithChildren<unknown>> = () => (
  <>
    {/* begin::Body */}
    <div className="g-5 g-xl-8">
      {/* begin trends in satisfaction */}
      <SatisfactionTrends title="Trends in satisfaction rate over time" className="card-xl-stretch mb-5 mb-xl-8" />
      {/* end trends in satisfaction */}

      {/* begin total button presses */}
      <ButtonPresses title="Total button presses over time" className="card-xl-stretch mb-5 mb-xl-8" />
      {/* end total button presses */}

      {/* begin percentage dist of button presses */}

      <PercentageDistButtonPresses
        className="card-xl-stretch mb-5 mb-xl-8"
        title={'Percentage distribution of button presses'}
      />
      {/* end percentage dist of button presses */}

      {/* begin:: device usage and fraud warning Row */}
      <div className="row">
        <div className="col-xl-7">
          <DeviceUsage title="Device Usage" className="mb-5 mb-xl-8" />
        </div>
        <div className="col-xl-5">
          <FraudWarning title="Fraud Warning" className="mb-5 mb-xl-8" />
        </div>
      </div>
      {/* end:: device usage and fraud warning Row */}
    </div>
  </>
)
