import React, { FC, useEffect, useRef, useState } from 'react'
import { Alert } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import moment from 'moment'
import { KTSVG } from '../../../../helpers'
import { Formik, FormikValues, Field, ErrorMessage, Form } from 'formik'
import { InterviewFormData, Role } from '../../../../apis/models'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createInterview, updateInterview, getUsersByRole } from '../../../../apis/app.service'
import { QueryKey } from '../../../../apis/models'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'

type Props = {
  inits?: any
}

const createInterviewSchema = Yup.object({
  name: Yup.string().required().label('Interview Name'),
  type: Yup.string().required().label('Type'),
  startDate: Yup.string().nullable().required().label('Start Date'),
  endDate: Yup.string().nullable().required().label('End Date'),
  interview: Yup.mixed().required().label('Interview File')
})

type Option = {
  value: string
  label: string
}

const typeOptions: Option[] = [
  { value: 'monthly', label: 'monthly' },
  { value: 'quartely', label: 'quartely' },
  { value: 'bi-annual', label: 'bi-annual' },
  { value: 'annual', label: 'annual' },
  { value: 'trend', label: 'trend' }
]

const CreateInterviews: FC<React.PropsWithChildren<Props>> = (props) => {
  const { action, data } = props.inits

  const fileRef = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [clientBodyOptions, setClientBodyOptions] = useState<Option[]>([])
  const [formIntialValues, setFormIntialValues] = useState<InterviewFormData>({
    name: '',
    type: 'monthly',
    startDate: null,
    endDate: null,
    interview: ''
  })
  const queryClient = useQueryClient()

  const { data: clientBodyIds } = useQuery([QueryKey.ClientBody], () => getUsersByRole(Role.ClientBody))

  useEffect(() => {
    if (clientBodyIds) {
      const options = clientBodyIds.map((clientBody: { user_Id: string; name: string }) => {
        return { value: clientBody.user_Id, label: clientBody.name }
      })
      setClientBodyOptions(options)
    }
  }, [clientBodyIds])

  useEffect(() => {
    let inits: InterviewFormData = {
      name: 'true',
      type: 'monthly',
      startDate: null,
      endDate: null,
      interview: ''
    }
    if (action === 'Edit') {
      const clientBodyOption = clientBodyOptions.find((option) => option.value === data.clientBody_Id)
      if (clientBodyOption) {
        inits = {
          name: data.name,
          type: data.type,
          startDate: data.period[0] ? new Date(data.period[0]) : null,
          endDate: data.period[1] ? new Date(data.period[1]) : null,
          interview: ''
        }
      }

      setFormIntialValues(inits)
    }
  }, [props.inits])

  const mutation = useMutation(createInterview, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries([QueryKey.Interviews])
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const mutationUpdate = useMutation(updateInterview, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries([QueryKey.Interviews])
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const submit = (values: InterviewFormData, actions: FormikValues) => {
    setLoading(true)
    const { name, type, startDate, endDate, interview } = values
    const formData = new FormData()

    // FormData only accepts string or blob types
    formData.append('name', name)
    formData.append('type', type)
    formData.append('startDate', moment(startDate).format('DD/MM/YYYY'))
    formData.append('endDate', moment(endDate).format('DD/MM/YYYY'))
    formData.append('interview', interview)

    if (action === 'Create') {
      mutation.mutate(formData)
    } else if (action === 'Edit') {
      mutationUpdate.mutate({ interviewId: data._id, body: formData })
    }
  }

  const reset = () => {
    setFormIntialValues({
      name: '',
      type: 'monthly',
      startDate: null,
      endDate: null,
      interview: ''
    })
    if (fileRef.current !== null) {
      fileRef.current.value = ''
    }
  }

  return (
    <div className="modal fade" id="kt_modal_create_interview" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Create Interview</h2>

            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={() => reset()}
            >
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            {/* start create form */}
            <div className="d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_device">
              {/* begin form body */}
              <div className="w-100 d-flex flex-column justify-center">
                <Formik
                  validationSchema={createInterviewSchema}
                  initialValues={formIntialValues}
                  onSubmit={submit}
                  encType="multipart/form-data"
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="form">
                      {/* begin form body */}
                      <div className="current" data-kt-stepper-element="content">
                        <div className="w-100">
                          {/* begin Interview name */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Interview Name</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify Interview Name"
                              ></i>
                            </label>

                            <Field type="text" className="form-control form-control-lg " name="name" placeholder="" />
                            <div className="text-danger">
                              <ErrorMessage name="name" />
                            </div>
                          </div>
                          {/* end Interview Name */}

                          {/* begin Interview type */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Type</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify Interview Name"
                              ></i>
                            </label>

                            <Select
                              onChange={(selectedOption: any) => formik.setFieldValue('type', selectedOption.value)}
                              options={typeOptions}
                              value={{ value: formik.values.type, label: formik.values.type }}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="type" />
                            </div>
                          </div>
                          {/* end Interview Type */}

                          {/* begin period row */}
                          <div className="row">
                            {/* begin startDate */}
                            <div className="col mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Start Date</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify start date"
                                ></i>
                              </label>

                              {/* <DatePickerComponent name="startDate" /> */}

                              <DatePicker
                                className="form-control form-control-lg"
                                selected={formik.values.startDate}
                                name="startDate"
                                onChange={(date: Date) => formik.setFieldValue('startDate', date)}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="startDate" />
                              </div>
                            </div>
                            {/* end startDate */}

                            {/* begin enddate */}
                            <div className="col mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">End Date</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify end date"
                                ></i>
                              </label>

                              <DatePicker
                                className="form-control form-control-lg"
                                selected={formik.values.endDate}
                                name="endDate"
                                onChange={(date: Date) => formik.setFieldValue('endDate', date)}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="endDate" />
                              </div>
                            </div>
                            {/* end enddate */}
                          </div>
                          {/* end period row */}

                          {/* begin upload file */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Upload Interview</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="upload Interview file"
                              ></i>
                            </label>

                            <input
                              type="file"
                              className="form-control form-control-lg"
                              name="interview"
                              onChange={(e: any) => {
                                formik.setFieldValue('interview', e.target.files[0])
                              }}
                              ref={fileRef}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="interview" />
                            </div>
                          </div>
                          {/* end upload file */}
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-center">
                        <button
                          //  data-bs-dismiss="modal"
                          type="submit"
                          className="btn btn-default d-flex"
                        >
                          {(!mutation.isLoading || !mutationUpdate.isLoading) && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {(mutation.isLoading || mutationUpdate.isLoading) && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* end form body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CreateInterviews }
