import React, { FC, useState, useEffect } from 'react'
import cogoToast from 'cogo-toast'
import { Formik, FormikValues, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { KTSVG } from '../../../../helpers'
import { createNewDevice, getUsersByRole, updateDevice } from '../../../../apis/app.service'
import { QueryKey } from '../../../../apis/models'
import { ICreateDevice, Role } from '../../../../apis/models'
import Multiselect from 'multiselect-react-dropdown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type DeviceFormValues = {
  name: string
  imei: string
  active: boolean
  category: string
  placement: string
  userId: string
  deploymentDate: Date | null
}

type Option = {
  name: string
  _id: string
  user_Id: string
}

type Props = {
  inits?: any
  data?: unknown
}

const createDeviceSchema = Yup.object({
  name: Yup.string().required().label('Device Name'),
  imei: Yup.string().required().label('IMEI'),
  active: Yup.string().required().label('Device Active'),
  category: Yup.string().required().label('Category'),
  placement: Yup.string().required().label('Placement'),
  userId: Yup.string().required().label('Assigned User')
})

const CreateDevice: FC<React.PropsWithChildren<Props>> = (props) => {
  const { action, data } = props.inits
  const queryClient = useQueryClient()

  const [selectedStation, setSelectedStation] = useState<any>([])
  const [formIntialValues, setFormIntialValues] = useState<DeviceFormValues>({
    name: '',
    imei: '',
    active: false,
    category: 'permanent',
    placement: '',
    userId: '',
    deploymentDate: null
  })

  const { data: stationIds } = useQuery([QueryKey.Station], () => getUsersByRole(Role.Station))

  useEffect(() => {
    let inits: DeviceFormValues = {
      name: '',
      imei: '',
      active: false,
      category: 'permanent',
      placement: '',
      userId: '',
      deploymentDate: null
    }
    setSelectedStation([])

    if (action === 'Edit') {
      const userIDs = data.user_Id.map((user: any) => user._id)
      const station: any = stationIds.find((user: Option) => userIDs.includes(user._id))
      if (station) {
        setSelectedStation([station])
      }

      inits = {
        name: data.device_name,
        imei: data.device_imei,
        active: data.active,
        category: data.category,
        placement: data.placement || '',
        userId: station ? station._id : '',
        deploymentDate: data.deploymentDate ? new Date(data.deploymentDate) : null
      }
    }
    setFormIntialValues(inits)
  }, [action, data])

  const mutation = useMutation(createNewDevice, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.Devices])
      const { message } = data
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const mutationUpdate = useMutation(updateDevice, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.Devices])
      const { message } = data
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const submit = (values: DeviceFormValues, actions: FormikValues) => {
    const deviceData: ICreateDevice = {
      device_name: values.name,
      device_imei: values.imei,
      active: values.active,
      category: values.category,
      userId: values.userId,
      placement: values.placement,
      deploymentDate: values.deploymentDate
    }

    if (action === 'Create') {
      mutation.mutate(deviceData)
    } else if (action === 'Edit') {
      mutationUpdate.mutate({ deviceId: data._id, body: deviceData })
    }
  }

  return (
    <div className="modal fade" id="kt_modal_create_device" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{action} Device</h2>

            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            {/* start create form */}
            <div className="d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_device">
              {/* begin form body */}
              <div className="w-100  d-flex flex-column justify-center">
                <Formik
                  validationSchema={createDeviceSchema}
                  initialValues={formIntialValues}
                  onSubmit={submit}
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="form" id="kt_modal_create_device_form">
                      {/* begin form body */}
                      <div className="current" data-kt-stepper-element="content">
                        <div className="w-100">
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Device Name</span>
                            </label>

                            <Field type="text" className="form-control form-control-lg" name="name" placeholder="" />
                            <div className="text-danger">
                              <ErrorMessage name="name" />
                            </div>
                          </div>
                          {/* end device Name */}

                          {/* begin device IMEI */}
                          <div className="row mb-10">
                            <div className="col">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Device IMEI</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Device IMEI is unique number on the GSM module of the device chip"
                                ></i>
                              </label>

                              <Field type="text" className="form-control form-control-lg " name="imei" placeholder="" />
                              <div className="text-danger">
                                <ErrorMessage name="imei" />
                              </div>
                            </div>

                            {/* end device IMEI */}

                            {/* begin device placement */}
                            <div className="col">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Device Placement</span>
                              </label>

                              <Field
                                type="text"
                                className="form-control form-control-lg "
                                name="placement"
                                placeholder=""
                              />
                              <div className="text-danger">
                                <ErrorMessage name="placement" />
                              </div>
                            </div>
                            {/* end device Placement */}
                          </div>

                          <div className="mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span>Assigned User</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Select the user to which this device will be allocated"
                              ></i>
                            </label>
                            <Multiselect
                              options={stationIds} // Options to display in the dropdown
                              className="col"
                              placeholder="Station"
                              selectedValues={selectedStation}
                              singleSelect={true}
                              style={{
                                chips: {
                                  // To change css chips(Selected options)
                                  background: '#F3992B'
                                },
                                optionContainer: {
                                  // To change css for option container
                                  border: 'none'
                                },
                                option: {
                                  // To change css for dropdown options
                                  // color: blue;
                                }
                              }}
                              onSelect={(list: Option[], item: Option) => formik.setFieldValue('userId', item._id)} // Function will trigger on select event
                              onRemove={(list: Option[], item: Option) => formik.setFieldValue('userId', '')} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                            />

                            <div className="text-danger">
                              <ErrorMessage name="userId" />
                            </div>
                          </div>

                          {/* begin row */}
                          <div className="row mb-10">
                            {/* begin deployment date */}
                            <div className="col-9">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Deployment Date</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Date when the device is to be deployed"
                                ></i>
                              </label>

                              <DatePicker
                                className="form-control form-control-lg w-100"
                                name="deploymentDate"
                                selected={formik.values.deploymentDate}
                                onChange={(date: Date) => formik.setFieldValue('deploymentDate', date)}
                              />
                            </div>
                            {/* end deployment date */}
                            {/* begin device active */}
                            <div className="col-3">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Device Active</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify if device is active to upload button press and notifications"
                                ></i>
                              </label>
                              <div className="form-check form-check-solid form-switch">
                                <input
                                  className="form-check-input w-45px h-30px"
                                  type="checkbox"
                                  checked={formik.values.active}
                                  onChange={(e) => {
                                    formik.setFieldValue('active', e.target.checked)
                                  }}
                                />
                              </div>

                              <div className="text-danger">
                                <ErrorMessage name="active" />
                              </div>
                            </div>

                            {/* end device active */}
                          </div>
                          {/* end row */}

                          {/* begin device catgory */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Device Category</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify device type"
                              ></i>
                            </label>

                            <div className="fv-row">
                              <Field as="select" className="form-select " name="category">
                                <option value="permanent">Permanent</option>

                                <option value="event">Event</option>

                                <option value="test">Test</option>
                              </Field>
                            </div>

                            <div className="text-danger">
                              <ErrorMessage name="category" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-center">
                        <button
                          //  data-bs-dismiss="modal"
                          type="submit"
                          className="btn btn-default d-flex"
                        >
                          {(!mutation.isLoading || !mutationUpdate.isLoading) && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {(mutation.isLoading || mutationUpdate.isLoading) && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* end form body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CreateDevice }
