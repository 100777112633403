import React, { FC, useState, useRef } from 'react'
import './style.css'
import { Modal, Button } from 'react-bootstrap'
// import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

interface Props {
  pdf: string
  onCancel: any
  visible: boolean
}

export const PdfViewer: FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const [scale, setScale] = useState(1)
  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages)
  }

  const onPage = (type: number) => {
    var newPage = type ? pageNumber + 1 : pageNumber - 1
    if (newPage > numPages) {
      newPage = 1
    } else if (newPage < 1) {
      newPage = numPages
    }
    setPageNumber(newPage)
  }

  const onSetScale = (type: any) => {
    var newScale = type ? scale + 0.1 : scale - 0.1

    if (newScale > 2) {
      newScale = 2
    } else if (newScale < 0.1) {
      newScale = 0.1
    }

    setScale(newScale)
  }

  const zoomStyle = {
    marginLeft: 10,
    cursor: 'pointer'
  }

  const footer = (
    <div className="footer">
      <Button onClick={() => onPage(0)}>Previous</Button>
      <div>
        <span style={{ textAlign: 'center' }}>
          Page {pageNumber} of {numPages}
        </span>
      </div>
      <Button onClick={() => onPage(1)}>Next</Button>
    </div>
  )

  return (
    <Modal
      maskClosable={false}
      onCancel={props.onCancel}
      visible={props.visible}
      width={'50%'}
      bodyStyle={{ height: 600, overflowY: 'auto' }}
      style={{ top: 20 }}
      footer={footer}
    >
      <div className="pdfWrapper">
        <Document file={props.pdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} height={600} />
        </Document>
      </div>
    </Modal>
  )
}
