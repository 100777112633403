import React, { useState } from 'react'
import { KTSVG } from '../../helpers'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../apis/models'
import './style.css'
import { updateUser } from '../../apis/app.service'
import { Alert } from 'react-bootstrap'
import { AUTH_LOCAL_KEY } from '../../apis/api.core'

const userData = JSON.parse(localStorage.getItem(AUTH_LOCAL_KEY)!)

const ProfilePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(true)
  const [email, setEmail] = useState<string>(userData?.data?.user?.email)
  const [phoneNumber, setPhoneNumber] = useState<string>(userData?.data?.user?.phoneNumber)
  const [name, setName] = useState<string>(userData?.data?.user?.name)
  const [password, setPassword] = useState<string>(userData?.data?.user?.details?.password)
  const [alertMsg, setAlertMsg] = useState('Info')
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertVariant, setAlertVariant] = useState('info')

  const queryClient = useQueryClient()

  const mutation = useMutation(updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.Users])
      const { message } = data
      setLoading(false)
      setAlertMsg('Profile updated successfully')
      setAlertVariant('success')
      setAlertVisible(true)
    },
    onError(error: Error, variables, context) {
      const { message } = error
      setLoading(false)
      setAlertMsg(message)
      setAlertVariant('danger')
      setAlertVisible(true)
    }
  })

  const submitForm = async () => {
    setLoading(true)
    //  mutation.mutate({
    //  });
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img
                src={'https://eu.ui-avatars.com/api/?name=' + userData?.data?.user?.name + '&size=250'}
                alt="profile pic"
              />{' '}
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                    {userData?.data?.user?.name}
                  </a>
                  <a href="#">
                    <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-1 svg-icon-primary" />
                  </a>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                    <KTSVG path="/media/icons/duotune/communication/com006.svg" className="svg-icon-4 me-1" />

                    {userData?.data?.user?.role}
                  </a>
                  <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                    <KTSVG path="/media/icons/duotune/communication/com011.svg" className="svg-icon-4 me-1" />
                    {userData?.data?.user?.email}
                  </a>
                </div>
              </div>

              <div className="d-flex my-4">
                <a href="#" className="btn btn-sm btn-default me-3" onClick={() => setEdit(false)}>
                  Edit Profile
                </a>
              </div>
            </div>

            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                  {/* begin::User Details Form */}
                  <div className="w-lg-800px bg-white d-flex flex-column rounded shadow-sm p-10 p-lg-15">
                    {/* begin::Heading */}
                    <div className="text-center mb-10">
                      <div className="text-gray-400 fw-bold fs-4">User Details </div>
                    </div>
                    {/* begin::Heading */}

                    <div className="row  mb-10">
                      {/* begin::Form group */}
                      <div className="form-group col">
                        <label className="form-label fs-6 fw-bolder text-dark">Name</label>
                        <input
                          disabled={edit}
                          className="form-control form-control-lg  mb-2"
                          name="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                      {/* end::Form group */}

                      {/* begin::Form group */}
                      <div className="form-group col">
                        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                        <input
                          disabled={edit}
                          className="form-control form-control-lg  mb-2"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                      {/* end::Form group */}
                    </div>

                    <div className="row mb-10">
                      {/* begin::Form group */}
                      <div className="form-group col">
                        <label className="form-label fs-6 fw-bolder text-dark">Phone Number</label>
                        <input
                          disabled={edit}
                          className="form-control form-control-lg  mb-2"
                          name="phonenumber"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                      {/* end::Form group */}

                      {/* begin::Form group */}
                      <div className="form-group col">
                        {/* begin::Label */}
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                        {/* end::Label */}

                        <input
                          disabled={edit}
                          type="password"
                          autoComplete="off"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control form-control-lg  mt-2"
                        />
                      </div>
                      {/* end::Form group */}
                    </div>
                    {/* begin: alert element */}
                    <Alert
                      show={alertVisible}
                      variant={alertVariant}
                      onClose={(e) => setAlertVisible(false)}
                      dismissible
                    >
                      {alertMsg}
                    </Alert>
                    {/* end: alert element */}
                    <div className="h-5px"></div>

                    {/* begin::Action */}
                    <div className="mx-auto">
                      <button
                        onClick={() => submitForm()}
                        type="button"
                        className="btn-lg btn-default w-100 mb-5"
                        style={{
                          boxShadow: '7px 10px 10px -11px rgba(87,85,87,1)',
                          borderWidth: 0,
                          color: 'black'
                        }}
                      >
                        {!loading && <span className="indicator-label">Save</span>}
                        {loading && (
                          <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                    {/* end::Action */}
                  </div>
                  {/* end::User Details Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProfilePage }
