import React, { FC, useEffect, useState, useRef } from 'react'
import cogoToast from 'cogo-toast'
import Multiselect from 'multiselect-react-dropdown'
import moment from 'moment'
import { KTSVG } from '../../../../helpers'
import { Formik, FormikValues, Field, ErrorMessage, Form } from 'formik'
import { ReportFormData, Role } from '../../../../apis/models'
import * as Yup from 'yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createNewReport, getUsersByRole, updateReport } from '../../../../apis/app.service'
import { QueryKey } from '../../../../apis/models'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

const createReportSchema = Yup.object({
  name: Yup.string().required().label('Report Name'),
  type: Yup.string().required().label('Type'),
  startDate: Yup.string().nullable().required().label('Start Date'),
  endDate: Yup.string().nullable().required().label('End Date'),
  report: Yup.mixed().nullable().required().label('Report File')
})

type Option = {
  name: string
  _id: string
  user_Id: string
}

type Props = {
  inits?: any
}

const options = [
  { value: 'monthly', label: 'monthly' },
  { value: 'quartely', label: 'quartely' },
  { value: 'bi-annual', label: 'bi-annual' },
  { value: 'annual', label: 'annual' },
  { value: 'trend', label: 'trend' }
]

const CreateReports: FC<React.PropsWithChildren<Props>> = (props) => {
  const { action, data } = props.inits

  const queryClient = useQueryClient()

  const fileRef = useRef<HTMLInputElement | null>(null)
  const [formIntialValues, setFormIntialValues] = useState<ReportFormData>({
    name: '',
    type: 'monthly',
    selectedClient: [],
    selectedClientBody: [],
    selectedStation: [],
    startDate: null,
    endDate: null,
    report: ''
  })
  const [tags, setTags] = useState<string[]>([])

  const mutation = useMutation(createNewReport, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.Reports])
      const { message } = data
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const mutationUpdate = useMutation(updateReport, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.Reports])
      const { message } = data
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const { data: clientIds } = useQuery([QueryKey.Client], () => getUsersByRole(Role.Client))

  const { data: clientBodyIds } = useQuery([QueryKey.ClientBody], () => getUsersByRole(Role.ClientBody))

  const { data: stationIds } = useQuery([QueryKey.Station], () => getUsersByRole(Role.Station))

  const submit = (values: ReportFormData, actions: FormikValues) => {
    const { name, type, startDate, endDate, report } = values
    const formData = new FormData()
    formData.append('name', name)
    formData.append('type', type)
    formData.append('recipientIds', tags.join())
    formData.append('startDate', moment(startDate).format('DD/MM/YYYY'))
    formData.append('endDate', moment(endDate).format('DD/MM/YYYY'))
    formData.append('report', report)

    if (action === 'Create') {
      mutation.mutate(formData)
    } else if (action === 'Edit') {
      mutationUpdate.mutate({ reportId: data._id, body: formData })
    }
  }

  const onSelect = (selectedList: Option[], selectedItem: Option) => {
    tags.push(selectedItem.user_Id)
  }

  const onRemove = (selectedList: Option[], selectedItem: Option) => {
    const index = tags.indexOf(selectedItem.user_Id)
    tags.splice(index, 1)
  }

  useEffect(() => {
    let inits: ReportFormData = {
      name: '',
      type: 'monthly',
      selectedClient: [],
      selectedClientBody: [],
      selectedStation: [],
      startDate: null,
      endDate: null,
      report: ''
    }
    if (action === 'Edit') {
      const selectedClient: any = clientIds.filter((user: Option) => data.recipientIds.includes(user.user_Id))
      const selectedClientBody: any = clientBodyIds.filter((user: Option) => data.recipientIds.includes(user.user_Id))
      const selectedStation: any = stationIds.filter((user: Option) => data.recipientIds.includes(user.user_Id))
      setTags([...data.recipientIds])

      inits = {
        name: data.name,
        type: data.type,
        selectedClient,
        selectedClientBody,
        selectedStation,
        startDate: data.period[0] ? new Date(data.period[0]) : null,
        endDate: data.period[1] ? new Date(data.period[1]) : null,
        report: ''
      }
    }
    setFormIntialValues(inits)
  }, [action, data])

  const onFormReset = () => {
    setFormIntialValues({
      name: '',
      type: 'monthly',
      selectedClient: [],
      selectedClientBody: [],
      selectedStation: [],
      startDate: null,
      endDate: null,
      report: ''
    })
    if (fileRef.current !== null) {
      fileRef.current.value = ''
    }
  }

  return (
    <div
      className="modal fade"
      id="kt_modal_create_report"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
    >
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
            <h2>{action} Report</h2>
            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" onClick={onFormReset}>
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            {/* start create form */}
            <div className="d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_device">
              {/* begin form body */}
              <div className="w-100 d-flex flex-column justify-center">
                <Formik
                  validationSchema={createReportSchema}
                  initialValues={formIntialValues}
                  onSubmit={submit}
                  encType="multipart/form-data"
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="form">
                      {/* begin form body */}
                      <div className="current" data-kt-stepper-element="content">
                        <div className="w-100">
                          {/* begin report name */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Report Name</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify Report Name"
                              ></i>
                            </label>

                            <Field type="text" className="form-control form-control-lg " name="name" placeholder="" />
                            <div className="text-danger">
                              <ErrorMessage name="name" />
                            </div>
                          </div>
                          {/* end report Name */}

                          {/* begin report type */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Type</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify report Name"
                              ></i>
                            </label>

                            <Select
                              name="type"
                              onChange={(selectedOption: any) => formik.setFieldValue('type', selectedOption.value)}
                              options={options}
                              value={{ value: formik.values.type, label: formik.values.type }}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="type" />
                            </div>
                          </div>
                          {/* end report Type */}

                          {/* begin receipientIds */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span>Receipients</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify Report Receipients"
                              ></i>
                            </label>

                            <div className="row">
                              <div className="my-2">
                                <Multiselect
                                  options={clientIds} // Options to display in the dropdown
                                  className="col"
                                  placeholder="Client"
                                  selectedValues={formik.values.selectedClient}
                                  style={{
                                    chips: {
                                      // To change css chips(Selected options)
                                      background: '#F3992B'
                                    },
                                    optionContainer: {
                                      // To change css for option container
                                      border: 'none'
                                    },
                                    option: {
                                      // To change css for dropdown options
                                      // color: blue;
                                    }
                                  }}
                                  onSelect={(list: Option[], item: Option) => onSelect(list, item)} // Function will trigger on select event
                                  onRemove={(list: Option[], item: Option) => onRemove(list, item)} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                />
                              </div>
                              <div className="my-2">
                                <Multiselect
                                  options={clientBodyIds} // Options to display in the dropdown
                                  className="col"
                                  placeholder="Institution"
                                  selectedValues={formik.values.selectedClientBody}
                                  style={{
                                    chips: {
                                      // To change css chips(Selected options)
                                      background: '#F3992B'
                                    },
                                    optionContainer: {
                                      // To change css for option container
                                      border: 'none'
                                    },
                                    option: {
                                      // To change css for dropdown options
                                      // color: blue;
                                    }
                                  }}
                                  onSelect={(list: Option[], item: Option) => onSelect(list, item)} // Function will trigger on select event
                                  onRemove={(list: Option[], item: Option) => onRemove(list, item)} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                />
                              </div>
                              <div className="my-2">
                                <Multiselect
                                  options={stationIds} // Options to display in the dropdown
                                  className="col"
                                  placeholder="Station"
                                  selectedValues={formik.values.selectedStation}
                                  style={{
                                    chips: {
                                      // To change css chips(Selected options)
                                      background: '#F3992B'
                                    },
                                    optionContainer: {
                                      // To change css for option container
                                      border: 'none'
                                    },
                                    option: {
                                      // To change css for dropdown options
                                      // color: blue;
                                    }
                                  }}
                                  onSelect={(list: Option[], item: Option) => onSelect(list, item)} // Function will trigger on select event
                                  onRemove={(list: Option[], item: Option) => onRemove(list, item)} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                />
                              </div>
                            </div>
                          </div>
                          {/* end receipientIds */}

                          {/* begin period row */}
                          <div className="row">
                            {/* begin startDate */}
                            <div className="col mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">Start Date</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify start date"
                                ></i>
                              </label>

                              <DatePicker
                                className="form-control form-control-lg"
                                selected={formik.values.startDate}
                                name="startDate"
                                onChange={(date: Date) => formik.setFieldValue('startDate', date)}
                              />

                              <div className="text-danger">
                                <ErrorMessage name="startDate" />
                              </div>
                            </div>
                            {/* end startDate */}

                            {/* begin enddate */}
                            <div className="col mb-10">
                              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                <span className="required">End Date</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Specify end date"
                                ></i>
                              </label>

                              <DatePicker
                                className="form-control form-control-lg"
                                selected={formik.values.endDate}
                                name="endDate"
                                onChange={(date: Date) => formik.setFieldValue('endDate', date)}
                              />
                              <div className="text-danger">
                                <ErrorMessage name="endDate" />
                              </div>
                            </div>
                            {/* end enddate */}
                          </div>
                          {/* end period row */}

                          {/* begin upload file */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Upload Report</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="upload report file"
                              ></i>
                            </label>

                            <input
                              type="file"
                              className="form-control form-control-lg"
                              name="report"
                              onChange={(e: any) => {
                                formik.setFieldValue('report', e.target.files[0])
                              }}
                              ref={fileRef}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="report" />
                            </div>
                          </div>
                          {/* end upload file */}
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-center">
                        <button
                          //  data-bs-dismiss="modal"
                          type="submit"
                          className="btn btn-default d-flex"
                        >
                          {(!mutation.isLoading || !mutationUpdate.isLoading) && (
                            <span className="indicator-label">Submit</span>
                          )}
                          {(mutation.isLoading || mutationUpdate.isLoading) && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* end form body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CreateReports }
