import React, { FC } from 'react'
import cogoToast from 'cogo-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KTSVG } from '../../../../helpers'
import { deleteDevice } from '../../../../apis/app.service'
import { QueryKey } from '../../../../apis/models'

type Props = {
  deviceId?: string
  deviceName?: string
}

const ConfirmDelete: FC<React.PropsWithChildren<Props>> = (props) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(deleteDevice, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries([QueryKey.Devices])
      cogoToast.success(message, { hideAfter: 3, position: 'top-right' })
    },
    onError(error: Error) {
      const { message } = error
      cogoToast.error(message, { hideAfter: 3, position: 'top-right' })
    }
  })

  const handleDelete = (reportId: string) => {
    cogoToast.info('Deleting record...', { hideAfter: 2, position: 'top-right' })
    mutation.mutate(reportId)
  }

  return (
    <>
      <div className="modal fade" id="kt_modal_confirm_delete" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-900px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Confirm Action</h2>

              <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
              </div>
            </div>

            <div className="modal-body py-lg-5 px-lg-5">
              Are you sure you want to delete <strong>{props.deviceName ?? ''}?</strong>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => handleDelete(props.deviceId ?? '')}
                data-bs-dismiss="modal"
                className="btn btn-lg btn-danger me-3"
              >
                {!mutation.isLoading && <span className="indicator-label">Delete</span>}
                {mutation.isLoading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default ConfirmDelete
