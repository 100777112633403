import React, { FC } from 'react'
import {
  StatisticsWidget5,
  ChartsWidget2,
  ChartsWidget3,
  TablesWidget11,
  SatisfactionTrends
} from '../../partials/widgets'

const DashboardWrapper: FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <div className="">
      {/* begin::Row */}
      <div className="row g-5 g-xl-8">
        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="/media/icons/duotune/communication/com006.svg"
            color="white"
            iconColor="dark"
            title="300"
            description="Total Users"
          />
        </div>
        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="/media/icons/duotune/abstract/abs006.svg"
            // svgIcon='/media/icons/duotune/abstract/abs006.svg'
            color="dark"
            iconColor="white"
            title="80"
            description="Total Devices"
          />
        </div>
        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="/media/icons/duotune/files/fil008.svg"
            color="warning"
            iconColor="white"
            title="120"
            description="Reports Generated"
          />
        </div>
        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-5 mb-xl-8"
            svgIcon="/media/icons/duotune/graphs/gra006.svg"
            color="secondary"
            iconColor="dark"
            title="150"
            description="Active Users"
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row g-5 g-xl-8">
        <div className="col-xl-6">
          <ChartsWidget2 className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-xl-6">
          <TablesWidget11 title="Recent Devices" className="mb-5 mb-xl-8" />
        </div>
      </div>
      {/* end::Row */}
      <div className="w-100">
        {/* begin trends in satisfaction */}
        <SatisfactionTrends title="Trends in satisfaction rate over time" className="card-xl-stretch mb-5 mb-xl-8" />
        {/* end trends in satisfaction */}
      </div>
    </div>
  </>
)

export { DashboardWrapper }
