import React from 'react'
import { KTSVG } from '../../../../helpers'
import { withStyles } from '@material-ui/core/styles'
import '../../core/styles.css'

const defaultToolbarStyles = {
  iconButton: {}
}

const CustomToolbar = (props) => {
  const openForm = () => {
    props.handleFormDisplay({ data: undefined, action: 'Create' })
  }
  return (
    <React.Fragment>
      {/* begin::Add user */}
      <button
        type="button"
        className="btn btn-default"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_create_device"
        onClick={() => openForm()}
      >
        <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
        Add Device
      </button>
      {/* end::Add user */}
    </React.Fragment>
  )
}

export default withStyles(defaultToolbarStyles, { name: 'CustomToolbar' })(CustomToolbar)
