import { APICore, AUTH_LOCAL_KEY } from './api.core'
import { AuthAction, ICreateDevice, IUser, Role } from './models'

const api = new APICore()
// ================ begin auth endpoints ===================
export const getAuth = async (authAction: AuthAction) => {
  switch (authAction.type) {
    case 'LOGIN':
      const response = await api.postAPI('/api/user/login', authAction.body)

      const { status, message, data } = response.data
      if (status !== 'success') {
        throw new Error(message)
      }
      localStorage.setItem(AUTH_LOCAL_KEY, data !== undefined ? JSON.stringify(data) : '')
      return data

    case 'LOGOUT':
      localStorage.removeItem(AUTH_LOCAL_KEY)

      window.location.reload()
      break

    default:
      break
  }
}

// ================ end auth endpoints ===================

// ================ begin user endpoints ===================
export const getUsers = async () => {
  const { status, message, data } = await api.getAPI('/api/user').then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }

  return data.docs
}

export const createNewUser = async (body: IUser) => {
  const { status, message, data } = await api.postAPI('/api/user', body).then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const updateUser = async (queryData: { userId: string; body: any }) => {
  const { status, message, data } = await api
    .patchAPI(`/api/user/${queryData.userId}`, queryData.body)
    .then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }

  return { message, data }
}
export const updateUserPassword = async (queryData: { userId: string; password: string }) => {
  const { status, message, data } = await api.patchAPI(`/api/user/updatepassword`, queryData).then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const deleteUser = async (userId: string) => {
  const { status, message } = await api.deleteAPI(`/api/user/${userId}`).then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }

  return { message }
}

export const getUsersByRole = async (role: Role) => {
  const response = await api.getAPI(`/api/user/userByRole/${role}`)
  const { status, message, data } = response.data

  if (status !== 'success') {
    throw new Error(message)
  }
  return data.docs
}

// ================ end user endpoints ===================

// ================ begin interview endpoints ==================

export const getInterviews = async () => {
  const { status, message, data } = await api.getAPI('/api/interview').then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }

  return data.docs
}
export const createInterview = async (body: FormData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  const { status, message, data } = await api.postAPI('/api/interview', body, config).then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const updateInterview = async (params: { interviewId: string; body: FormData }) => {
  const { interviewId, body } = params
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  const { status, message, data } = await api
    .patchAPI(`/api/interview/${interviewId}`, body, config)
    .then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const deleteInterview = async (interviewId: string) => {
  const { status, message, data } = await api.deleteAPI(`/api/interview/${interviewId}`).then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

// ================ end interview endpoints ===================

// ================ begin report endpoints ===================
export const getReportData = async () => {
  const { status, message, data } = await api.getAPI('/api/report').then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }

  return data.docs
}

export const createNewReport = async (body: FormData) => {
  const { status, message, data } = await api
    .postAPI('/api/report', body, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const updateReport = async (params: { reportId: string; body: FormData }) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  const { status, message, data } = await api
    .patchAPI(`/api/report/${params.reportId}`, params.body, config)
    .then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const deleteReport = async (reportId: string) => {
  const { status, message } = await api.deleteAPI(`/api/report/${reportId}`).then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message }
}

// ================ end report endpoints ===================

// ================ begin device endpoints ===================
export const getDevices = async () => {
  const { status, message, data } = await api.getAPI('/api/device').then((res) => res.data)

  if (status !== 'success') {
    throw new Error(message)
  }

  return data.docs
}

export const createNewDevice = async (body: ICreateDevice) => {
  const { status, message, data } = await api.postAPI('/api/device', body).then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const updateDevice = async (params: { deviceId: string; body: any }) => {
  const { status, message, data } = await api
    .patchAPI(`/api/device/${params.deviceId}`, params.body)
    .then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message, data }
}

export const deleteDevice = async (deviceId: string) => {
  const { status, message } = await api.deleteAPI(`/api/device/${deviceId}`).then((res) => res.data)
  if (status !== 'success') {
    throw new Error(message)
  }
  return { message }
}

// ================ end device endpoints ===================
