import React, { FC } from 'react'
import UsersTableComponent from './UsersDataTable'
import './core/style.css'

const UsersPage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <UsersTableComponent />
    </>
  )
}

export { UsersPage }
