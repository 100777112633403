import React, { useState, useCallback } from 'react'
import { KTSVG } from '../../helpers'
import MUIDataTable from 'mui-datatables'
import { useQuery } from '@tanstack/react-query'
import { LoadingSpinner } from '../shared_components/LoadingSpinner'
import { QueryKey } from '../../apis/models'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import CustomToolbar from './components/toolbar/custom-tb'
import './core/styles.css'
import { getDevices } from '../../apis/app.service'
import { CreateDevice } from './components/forms/create-device'
import ConfirmActive from './components/confirmActive/confirm-active'
import ConfirmDelete from './components/confirmDelete/confirm-delete'

const muiCache = createCache({
  key: 'mui',
  prepend: true
})

const DevicesTableComponent = () => {
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  const [selectedDeviceName, setselectedDeviceName] = useState('')
  const [selectedIsActive, setSelectedIsActive] = useState(false)
  const [selectedRecordData, setSelectedRecordData] = useState({ action: 'Create' })

  const handleFormDisplay = useCallback((inits) => {
    setSelectedRecordData(inits)
  }, [])

  const columns = [
    {
      name: 'device_id',
      label: 'Device Id',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'device_name',
      label: 'Device Info',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'device_imei',
      label: 'IMEI',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const data = tableMeta.tableData[tableMeta.rowIndex]
          return (
            <div>
              <div
                className="form-check form-check-solid form-switch"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_confirm_active"
              >
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  checked={value}
                  onChange={(e) => {
                    setSelectedDeviceId(data._id)
                    setselectedDeviceName(data.device_name)
                    setSelectedIsActive(e.target.checked)
                  }}
                />
              </div>
            </div>
          )
        }
      }
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'total',
      label: 'No. of presses',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'averageSatisfaction',
      label: 'Average %',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const data = tableMeta.tableData[tableMeta.rowIndex]
          return (
            <>
              {/* begin::Menu */}
              <div className="d-flex flex-row align-content-center w-125px py-2" data-kt-menu="true">
                {/* begin::Menu item */}
                <a
                  className="btn-icon-default pe-5"
                  onClick={() => {
                    const data = { data: tableMeta.tableData[tableMeta.rowIndex], action: 'Edit' }
                    setSelectedRecordData(data)
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_device"
                >
                  <i className="fas fa-edit btn-icon-default" data-bs-toggle="tooltip" title="Edit"></i>
                </a>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <a
                  className="btn-icon-default pe-5"
                  onClick={() => {
                    setSelectedDeviceId(data._id)
                    setselectedDeviceName(data.device_name)
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_delete"
                >
                  <i className="fas fa-trash btn-icon-default" data-bs-toggle="tooltip" title="Delete"></i>
                </a>
                {/* end::Menu item */}
                <a
                  className="btn-icon-default"
                  onClick={() => downloadFile(tableMeta.tableData[tableMeta.rowIndex].device_id)}
                >
                  <i className="fas fa-download btn-icon-default" data-bs-toggle="tooltip" title="Device Data"></i>
                </a>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          )
        }
      }
    }
  ]

  const options = {
    filter: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'sema_devices.csv', separator: ',' },
    elevation: 1,
    customToolbar: () => {
      return <CustomToolbar handleFormDisplay={handleFormDisplay} />
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div className="card-title">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG path="/media/icons/duotune/general/gen021.svg" className="svg-icon-1 position-absolute ms-6" />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14 mt-3"
              placeholder="Search Device"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
      )
    }
  }

  const { data, isLoading, isError, error } = useQuery([QueryKey.Devices], getDevices, { keepPreviousData: true })

  const downloadFile = (deviceId) => {
    //TODO: Consider adding auth to these endpoint because its public now
    const downloadLink = `${process.env.REACT_APP_BASE_URL}/api/device/${deviceId}/feedback`
    fetch(downloadLink, {
      method: 'GET'
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = `${deviceId}.csv`
        a.click()
      })
    })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <div className="d-flex justify-center align-content-center">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
        <p>{error}</p>
      </div>
    )
  }

  return (
    <>
      <CacheProvider value={muiCache}>
        <MUIDataTable title={'Devices'} data={data} columns={columns} options={options} />
      </CacheProvider>
      <CreateDevice inits={selectedRecordData} />
      <ConfirmDelete deviceId={selectedDeviceId} deviceName={selectedDeviceName} />
      <ConfirmActive deviceId={selectedDeviceId} deviceName={selectedDeviceName} isActive={selectedIsActive} />
    </>
  )
}

export default DevicesTableComponent
