import React, { FC } from 'react'

import ReportsDataTable from './ReportsDataTable'

const ReportsPage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <ReportsDataTable />
    </>
  )
}

export { ReportsPage }
